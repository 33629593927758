import { Component, OnInit } from '@angular/core';
import { Gesture, GestureController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-galllery',
  templateUrl: './galllery.page.html',
  styleUrls: ['./galllery.page.scss'],
})
export class GallleryPage implements OnInit {

  slideOpts = {
    initialSlide: 0,
    spaceBetween:0,
    speed: 400
  };
  gallery: any = [];
  index: number;
  type: any;

  constructor(
    private modalController: ModalController,
    private gestureCtrl: GestureController,
  ) { }


  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  ngOnInit() {
    this.setupSwipeGesture();
  }

  setupSwipeGesture() {
    const gesture: Gesture = this.gestureCtrl.create({
      el: document.querySelector('.gallery-modal-wrapper'),
      gestureName: 'swipe',
      direction: 'y',
      threshold: 80,
      gesturePriority: 100,
      passive: false,
      onStart: ev => {
        // Handle gesture start event
        // console.log("onStart")
      },
      onMove: ev => {
        // Handle gesture move event
        const deltaY = ev.deltaY;
        if (Math.abs(deltaY) >= 80) {
          this.closeModal();
        }

      },
      onEnd: ev => {
        // console.log("onEnd")
      }
    });

    gesture.enable(true);
  }
}
