import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./beforeLogin/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./beforeLogin/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'loader',
    loadChildren: () => import('./beforeLogin/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'signup/:role',
    loadChildren: () => import('./beforeLogin/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./beforeLogin/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'incorrect-email',
    loadChildren: () => import('./beforeLogin/incorrect-email/incorrect-email.module').then( m => m.IncorrectEmailPageModule)
  },
  {
    path: 'specialcode',
    loadChildren: () => import('./beforeLogin/specialcode/specialcode.module').then( m => m.SpecialcodePageModule)
  },
  {
    path: 'emailverified',
    loadChildren: () => import('./beforeLogin/emailverified/emailverified.module').then( m => m.EmailverifiedPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./beforeLogin/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./commonpages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./commonpages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./commonpages/editprofile/editprofile.module').then( m => m.EditprofilePageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./commonpages/editprofile/editprofile.module').then( m => m.EditprofilePageModule)
  },
  {
    path: 'selected-stocks',
    loadChildren: () => import('./selected-stocks/selected-stocks.module').then( m => m.SelectedStocksPageModule)
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./leaderboard/leaderboard.module').then( m => m.LeaderboardPageModule)
  },
  {
    path: 'stocklisting',
    loadChildren: () => import('./stocklisting/stocklisting.module').then( m => m.StocklistingPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'you-can',
    loadChildren: () => import('./beforeLogin/you-can/you-can.module').then( m => m.YouCanPageModule)
  },
  {
    path: 'choose-home',
    loadChildren: () => import('./beforeLogin/choose-home/choose-home.module').then( m => m.ChooseHomePageModule)
  },
  {
    path: 'properties-for-rent',
    loadChildren: () => import('./pages/properties-for-rent/properties-for-rent.module').then( m => m.PropertiesForRentPageModule)
  },
  {
    path: 'selected-listing',
    loadChildren: () => import('./pages/selected-listing/selected-listing.module').then( m => m.SelectedListingPageModule)
  },
  {
    path: 'message-host/:post_id',
    loadChildren: () => import('./pages/message-host/message-host.module').then( m => m.MessageHostPageModule)
  },
  {
    path: 'message-sent',
    loadChildren: () => import('./modals/message-sent/message-sent.module').then( m => m.MessageSentPageModule)
  },
  {
    path: 'calendar/:post_id',
    loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'request-date/:post_id',
    loadChildren: () => import('./pages/request-date/request-date.module').then( m => m.RequestDatePageModule)
  },
  {
    path: 'properties-for-sale',
    loadChildren: () => import('./pages/properties-for-sale/properties-for-sale.module').then( m => m.PropertiesForSalePageModule)
  },
  {
    path: 'properties-sale-detail/:post_id',
    loadChildren: () => import('./pages/properties-sale-detail/properties-sale-detail.module').then( m => m.PropertiesSaleDetailPageModule)
  },
  {
    path: 'message-seller',
    loadChildren: () => import('./pages/message-seller/message-seller.module').then( m => m.MessageSellerPageModule)
  },
  {
    path: 'make-offer',
    loadChildren: () => import('./pages/make-offer/make-offer.module').then( m => m.MakeOfferPageModule)
  },
  {
    path: 'all-events',
    loadChildren: () => import('./pages/all-events/all-events.module').then( m => m.AllEventsPageModule)
  },
  {
    path: 'selected-event/:post_id',
    loadChildren: () => import('./pages/selected-event/selected-event.module').then( m => m.SelectedEventPageModule)
  },
  {
    path: 'event-tickets',
    loadChildren: () => import('./pages/event-tickets/event-tickets.module').then( m => m.EventTicketsPageModule)
  },
  {
    path: 'tickets-description',
    loadChildren: () => import('./pages/tickets-description/tickets-description.module').then( m => m.TicketsDescriptionPageModule)
  },
  {
    path: 'selected-staycation/:post_id',
    loadChildren: () => import('./pages/selected-staycation/selected-staycation.module').then( m => m.SelectedStaycationPageModule)
  },
  {
    path: 'cancel-message/:id/:type',
    loadChildren: () => import('./pages/cancel-message/cancel-message.module').then( m => m.CancelMessagePageModule)
  },
  {
    path: 'selected-staycation-event/:post_id',
    loadChildren: () => import('./pages/selected-staycation-event/selected-staycation-event.module').then( m => m.SelectedStaycationEventPageModule)
  },
  {
    path: 'review-venue-experience/:id/:type',
    loadChildren: () => import('./pages/review-venue-experience/review-venue-experience.module').then( m => m.ReviewVenueExperiencePageModule)
  },
  {
    path: 'completed-staycation/:post_id',
    loadChildren: () => import('./pages/completed-staycation/completed-staycation.module').then( m => m.CompletedStaycationPageModule)
  },
  {
    path: 'review-host/:id/:type',
    loadChildren: () => import('./pages/review-host/review-host.module').then( m => m.ReviewHostPageModule)
  },
  {
    path: 'review-host/:post_id',
    loadChildren: () => import('./pages/review-host/review-host.module').then( m => m.ReviewHostPageModule)
  },
  {
    path: 'my-favorites-list',
    loadChildren: () => import('./pages/my-favorites-list/my-favorites-list.module').then( m => m.MyFavoritesListPageModule)
  },
  {
    path: 'delete',
    loadChildren: () => import('./modals/delete/delete.module').then( m => m.DeletePageModule)
  },
  {
    path: 'favorites-list/:fvt_Category',
    loadChildren: () => import('./pages/favorites-list/favorites-list.module').then( m => m.FavoritesListPageModule)
  },
  {
    path: 'favorites-details/:post_id',
    loadChildren: () => import('./pages/favorites-details/favorites-details.module').then( m => m.FavoritesDetailsPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./commonpages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./commonpages/setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./commonpages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'ticket-sales',
    loadChildren: () => import('./pages/ticket-sales/ticket-sales.module').then( m => m.TicketSalesPageModule)
  },
  {
    path: 'crypto-bezel',
    loadChildren: () => import('./pages/crypto-bezel/crypto-bezel.module').then( m => m.CryptoBezelPageModule)
  },
  {
    path: 'property-filter',
    loadChildren: () => import('./pages/property-filter/property-filter.module').then( m => m.PropertyFilterPageModule)
  },
  {
    path: 'edit-for-sale-listing',
    loadChildren: () => import('./pages/edit-for-sale-listing/edit-for-sale-listing.module').then( m => m.EditForSaleListingPageModule)
  },
  {
    path: 'edit-tickets',
    loadChildren: () => import('./pages/edit-tickets/edit-tickets.module').then( m => m.EditTicketsPageModule)
  },
  {
    path: 'properties-for-rent-add-edit',
    loadChildren: () => import('./pages/properties-for-rent-add-edit/properties-for-rent-add-edit.module').then( m => m.PropertiesForRentAddEditPageModule)
  },
  {
    path: 'choose',
    loadChildren: () => import('./pages/choose/choose.module').then( m => m.ChoosePageModule)
  },
  {
    path: 'host-home',
    
    loadChildren: () => import('./pages/host-home/host-home.module').then( m => m.HostHomePageModule)
  },
  {
    path: 'galllery',
    loadChildren: () => import('./modals/galllery/galllery.module').then( m => m.GallleryPageModule)
  },
  {
    path: 'review-event-tickets',
    loadChildren: () => import('./pages/host/review-event-tickets/review-event-tickets.module').then( m => m.ReviewEventTicketsPageModule)
  },
  {
    path: 'my-bookings-and-events',
    loadChildren: () => import('./pages/host/my-bookings-and-events/my-bookings-and-events.module').then( m => m.MyBookingsAndEventsPageModule)
  },
  {
    path: 'selected-booking/:post_id',
    loadChildren: () => import('./pages/host/selected-booking/selected-booking.module').then( m => m.SelectedBookingPageModule)
  },
  {
    path: 'properties-for-rent-details/:post_id',
    loadChildren: () => import('./pages/properties-for-rent-details/properties-for-rent-details.module').then( m => m.PropertiesForRentDetailsPageModule)
  },
  {
    path: 'choose-role',
    loadChildren: () => import('./beforeLogin/choose-role/choose-role.module').then( m => m.ChooseRolePageModule)
  },
  {
    path: 'tickets-for-event/:user_id',
    loadChildren: () => import('./pages/tickets-for-event/tickets-for-event.module').then( m => m.TicketsForEventPageModule)
  },
  {
    path: 'tickets-for-event-details/:post_id',
    loadChildren: () => import('./pages/tickets-for-event-details/tickets-for-event-details.module').then( m => m.TicketsForEventDetailsPageModule)
  },
  {
    path: 'my-listings',
    loadChildren: () => import('./pages/my-listings/my-listings.module').then( m => m.MyListingsPageModule)
  },
  {
    path: 'success-msg',
    loadChildren: () => import('./modals/success-msg/success-msg.module').then( m => m.SuccessMsgPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./beforeLogin/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'review-listing-screen',
    loadChildren: () => import('./pages/review-listing-screen/review-listing-screen.module').then( m => m.ReviewListingScreenPageModule),
   
  },
  {
    path: 'my-listings-screen',
    loadChildren: () => import('./pages/my-listings-screen/my-listings-screen.module').then( m => m.MyListingsScreenPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./tabpages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'add-choose',
    loadChildren: () => import('./pages/add-choose/add-choose.module').then( m => m.AddChoosePageModule)
  },
  {
    path: 'search-modal',
    loadChildren: () => import('./modals/search-modal/search-modal.module').then( m => m.SearchModalPageModule)
  },
  {
    path: 'my-bookmarks',
    loadChildren: () => import('./pages/my-bookmarks/my-bookmarks.module').then( m => m.MyBookmarksPageModule)
  },

  {
    path : 'offer/:post_id',
    loadChildren :() => import('./pages/review-listing-screen/offer/offer-routing.module').then( m => m.OfferPageRoutingModule)
  },
  {
    path :'market',
    loadChildren :() => import('./tabpages/market/market-routing.module').then( m => m.MarketPageRoutingModule)
  },
  {
    path: 'view-event-review/:id',
    loadChildren: () => import('./pages/view-event-review/view-event-review.module').then( m => m.ViewEventReviewPageModule)
  },
  {
    path: 'offer-request',
    loadChildren: () => import('./pages/offer-request/offer-request.module').then( m => m.OfferRequestPageModule)
  },
  {
    path: 'rent-request-details/:post_id',
    loadChildren: () => import('./pages/rent-request-details/rent-request-details.module').then( m => m.RentRequestDetailsPageModule)
  },
  {
    path: 'another-user-profile/:user_id',
    loadChildren: () => import('./pages/host/another-user-profile/another-user-profile.module').then( m => m.AnotherUserProfilePageModule)
  },
  {
    path: 'offer-request-cancel',
    loadChildren: () => import('./pages/offer-request-cancel/offer-request-cancel.module').then( m => m.OfferRequestCancelPageModule)
  },
  {
    path: 'offer-request-cancel/:post_id',
    loadChildren: () => import('./pages/offer-request-cancel/offer-request-cancel.module').then( m => m.OfferRequestCancelPageModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('./commonpages/cards/cards.module').then( m => m.CardsPageModule)
  },
  {
    path: 'add-card/:type',
    loadChildren: () => import('./commonpages/add-card/add-card.module').then( m => m.AddCardPageModule)
  },
  {
    path: 'cardinfo',
    loadChildren: () => import('./commonpages/cardinfo/cardinfo.module').then( m => m.CardinfoPageModule)
  },
  {
    path: 'card-edit',
    loadChildren: () => import('./commonpages/card-edit/card-edit.module').then( m => m.CardEditPageModule)
  },
  {
    path: 'kyc',
    loadChildren: () => import('./commonpages/kyc/kyc.module').then( m => m.KycPageModule)
  },
  {
    path: 'add-debitcard',
    loadChildren: () => import('./commonpages/add-debitcard/add-debitcard.module').then( m => m.AddDebitcardPageModule)
  },
  {
    path: 'make-payment/:id/:type',
    loadChildren: () => import('./pages/make-payment/make-payment.module').then( m => m.MakePaymentPageModule)
  },
  {
    path: 'my-wallet',
    loadChildren: () => import('./pages/host/my-wallet/my-wallet.module').then( m => m.MyWalletPageModule)
  },
  {
    path: 'message-details/:id',
    loadChildren: () => import('./pages/message-details/message-details.module').then( m => m.MessageDetailsPageModule)
  },
  {
    path: 'chat-history',
    loadChildren: () => import('./modals/chat-history/chat-history.module').then( m => m.ChatHistoryPageModule)
  },
  {
    path: 'cancellation-policy',
    loadChildren: () => import('./modals/cancellation-policy/cancellation-policy.module').then( m => m.CancellationPolicyPageModule)
  },
  {
    path: 'booked-ticket-listing/:post_id/:type_details_id',
    loadChildren: () => import('./pages/booked-ticket-listing/booked-ticket-listing.module').then( m => m.BookedTicketListingPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./pages/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },
  {
    path: 'term-of-service',
    loadChildren: () => import('./commonpages/term-of-service/term-of-service.module').then( m => m.TermOfServicePageModule)
  },
  {
    path: 'property-messages/:post_id/:profile_id',
    loadChildren: () => import('./pages/property-messages/property-messages.module').then( m => m.PropertyMessagesPageModule)
  },
  {
    path: 'booked-tickets-details/:post_id/:ticket_detail_id',
    loadChildren: () => import('./pages/booked-tickets-details/booked-tickets-details.module').then( m => m.BookedTicketsDetailsPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./beforeLogin/terms/terms-routing.module').then( m => m.TermsPageRoutingModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./beforeLogin/privacy/privacy-routing.module').then( m => m.PrivacyPageRoutingModule)
  },
  {
    path: 'my-tickets',
    loadChildren: () => import('./pages/my-tickets/my-tickets.module').then( m => m.MyTicketsPageModule)
  },
  {
    path: 'my-ticket-details',
    loadChildren: () => import('./pages/my-ticket-details/my-ticket-details.module').then( m => m.MyTicketDetailsPageModule)
  },
  {
    path: 'payout-cards',
    loadChildren: () => import('./payout-cards/payout-cards.module').then( m => m.PayoutCardsPageModule)
  },
  {
    path: 'custom-splash',
    loadChildren: () => import('./pages/custom-splash/custom-splash.module').then( m => m.CustomSplashPageModule)
  }



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  // providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule {}
