import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';

import { PrefService } from './services/common/pref.service';
import { StorageEngineService } from './services/common/storage-engine.service';
import { LocalStorageService } from './services/common/local-storage.service';
import { UtilsService } from './services/common/utils.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { GallleryPageModule } from '../app/modals/galllery/galllery.module';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
// import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { Screenshot } from '@ionic-native/screenshot/ngx';
import { FormBuilder } from '@angular/forms';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';


import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { TokenInterceptorService } from './token-interceptor.service';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios'
    }), AppRoutingModule,
    GallleryPageModule,
    
  ],
  providers: [

    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    

    Camera,
    NativeStorage,
    CallNumber,
    StorageEngineService,
    InAppBrowser,
    LocalStorageService,
    UtilsService,
    OneSignal,
    PrefService,
    SocialSharing,
    Stripe,
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // FirebaseDynamicLinks,
    Deeplinks,
    Clipboard,
    FormBuilder,
    Screenshot,
    PreviewAnyFile
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
