import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertControllerService {

  constructor(
    private alertController: AlertController,
    private toastController: ToastController
  ) { }


  presentAlert(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Yes',
            handler: () => resolve(true)
          }, {
            text: 'No',
            role: 'cancel',
            handler: () => reject(false)
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertWithHeader(alertHeader: string, alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        header: alertHeader,
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Yes',
            handler: () => resolve(true)
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(false)
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertWithButtons(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        header: 'Alert',
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(true)
          }
        ]
      });

      await alert.present();
    });
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }
}
