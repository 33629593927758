import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, NavController, ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  isLoading = false;
  loading: any;


  constructor(
    public loadingCtrl: LoadingController,
    public alertController: AlertController,
    public toastController: ToastController,
    public router: Router,
    public nav: NavController
  ) { }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  async hideLoading() {
    this.isLoading = false;
    this.loadingCtrl.getTop().then(loader => {
      if (loader) {
        loader.dismiss();
      }
    });
  }

  async showLoading(message?: string) {
    this.isLoading = true;
    this.loadingCtrl.create({
      message: message ? message : 'Please wait...'
    }).then(loader => {
      loader.present().then(() => {
        if (!this.isLoading) {
          loader.dismiss();
        }
      });
    });
  }

  async showLoader(msg: string = '') {
    if (msg === '') {
      msg = 'Please wait...';
    }
    this.loading = await this.loadingCtrl.create({ message: msg });
    return await this.loading.present();
  }

  presentAlertWithButtons(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        header: 'Alert',
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(true)
          }
        ]
      });

      await alert.present();
    });
  }

  async presentToast(msg: string = '') {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }

  async presentAlert(msg, heading = 'Alert', route = '',) {
    let alert = await this.alertController.create({
      header: heading,
      message: msg,
      backdropDismiss: false,
      buttons: [{
        text: 'OK',
        handler: () => {
          if (route != '') {
            this.router.navigate([route]);
          }
        }
      }]
    });
    await alert.present();
  }






  async guestAlert(msg = '', heading = 'Alert', route = '',) {
    if (!msg) {
      msg = "Please log in to your account to access this feature. If you don't have an account, you can sign up for one!";
    }
    if (!route) {
      route = '/login';
    }
    let alert = await this.alertController.create({
      header: heading,
      message: msg,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
            console.log('inside the cancel');
          }
        },
        {
          text: 'Okay',
          handler: () => {
            this.router.navigate([route]);
          }
        }
      ]
    });
    await alert.present();
  }


  back() {
    this.nav.back()
  }
}
