import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StorageEngineService } from './storage-engine.service';
import { UtilsService } from './utils.service';
import { Events } from './events.service';
import { PlatformLocation } from '@angular/common';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable()
export class PrefService {
    authState  = new BehaviorSubject(false);
    isLoggedIn = false;
    token: any;
    storageValue: any;
    public storageEngine: any;

    constructor(
        public storageEngineService: StorageEngineService,
        public utilsService: UtilsService,
        public events: Events,
        public router: Router,
        public platformLocation: PlatformLocation,
        private plat: Platform
    ) {
        this.plat.ready().then(() => {
            this.ifLoggedIn();
        });
        this.storageEngine = this.storageEngineService.getStorageEngine();
    }

    public getUtilsService() {
        return this.utilsService;
    }

    getEventService() {
        return this.events;
    }

    public getStorageEngine() {
        return this.storageEngine;
    }

    clearStorage() {
        this.storageEngine.clear();
    }

    getStorageItem(key: string) {
        return this.storageEngine.getItem(key);
    }

    removeStorageItem(key: string) {
        this.storageEngine.removeItem(key);
    }

    setStorageItem(prop: any, value: any) {
        this.storageEngine.setItem(prop, value);
    }

    cleanAppData() {
        this.storageEngine.removeItem('user_res');
        this.storageEngine.removeItem('token');
        this.storageEngine.removeItem('role');
      this.storageEngine.removeItem('push_id');
      this.storageEngine.removeItem('banners');
      this.storageEngine.removeItem('home_modules');
      this.storageEngine.removeItem('weekly_message');
      this.storageEngine.removeItem('sub_content');
    }

    showErrorMessage(message) {
        // implement alert or toast
    }

    errorHandlingRequest(error) {
        if (error.status === 401) {
            this.cleanAppData();
            this.showErrorMessage('Your session has been expired, Please login!');
            this.router.navigateByUrl('/');
        } else {
            let msg = 'Something went wrong!';
            if (error.error.message !== undefined && error.error.message !== '') {
                msg = error.error.message;
            }
            this.showErrorMessage(msg);
        }
    }

    getToken() {
        return this.storageEngine.getItem('token').then(
            (data) => {
                this.token = data;
                if (this.token != null) {
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            },
            (error) => {
                this.token      = null;
                this.isLoggedIn = false;
            }
        );
    }

    setUserInformation(userInfo): any {
        return this.storageEngine.setItem('userInformation', userInfo);
    }

    getUserInformation(): any {
        return this.storageEngine.getItem('userInformation');
    }

    setPreRegistrationAssessment(assessmentData): any {
        return this.storageEngine.setItem(
            'pre-registration-assessment',
            assessmentData
        );
    }

    getPreRegistrationAssessment(): any {
        return this.storageEngine.getItem('pre-registration-assessment');
    }

    setUserIsLoggedIn(loggedIn): any {
        return this.storageEngine.setItem('LoggedIn', loggedIn);
    }

    getUserIsLoggedIn(): any {
        return this.storageEngine.getItem('LoggedIn');
    }

    setUserAttributes(attrs): any {
        return this.storageEngine.setItem('userAttributes', attrs);
    }

    getUserAttributes(): any {
        return this.storageEngine.getItem('userAttributes');
    }

    setUserConfirmationStatus(status): any {
        return this.storageEngine.setItem('userConfirmStatus', status);
    }

    getUserConfirmationStatus(): any {
        return this.storageEngine.getItem('userConfirmStatus');
    }

    removeUserConfirmationStatus(): any {
        return this.storageEngine.removeItem('userConfirmStatus');
    }

    getUrlObject(): string {
        return (this.platformLocation as any).location;
    }

    getBaseUrl(): string {
        return (this.platformLocation as any).location.origin;
    }

    getCurrentUrl(): string {
        return (this.platformLocation as any).location.href;
    }

    getPlatformData() {
        return this.storageEngine.getItem('Platform').then(
            (data) => {
                return data;
            },
            (error) => {
                return {};
            }
        );
    }

    ifLoggedIn() {
        let res = this.getStorageItem('user_res');
        if (res) {
        }
    }

    isAuthenticated() {
        return this.authState.value;
    }
}
